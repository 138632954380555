.embedding-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: fixed; */
    margin: 20px 0;
    margin-bottom: 55px;
}

.embedding-selector {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 250px;
    font-family: "ScalySansRemake", sans-serif;
    background-color: white;
}

.embedding-selector:focus {
    outline: none;
    border-color: #aaa;
}

#loadEmbeddingButton {
    background-image: url('https://imagedelivery.net/SahcvrNe_-ej4lTB6vsAZA/b45d096d-a2c0-4ea5-5f09-20d759f42400/blogpost');
    background-size: 100%;
    background-position: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
}

#loadEmbeddingButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.selector-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.embedding-selector-header {
    margin-bottom: 10px;
}