nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    height: 100vh;
    background-color: #010101;
    z-index: 1000;
}

nav ul {
    margin: 0;
    padding: 0 0 0 0;
    list-style: none;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

nav ul li {
    width: 60px;
    height: 60px;
    margin: 0;
}

nav ul li a {
    display: block;
    width: 100%;
    height: 100%;
}

nav ul li a.logo-link img {
    width: 60px;
    height: 60px;
    display: block;
}

nav ul li a.login-btn {
    background-color: #d4b8a7;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 60px;
    text-decoration: none;
}

nav ul li a:hover {
    background-color: #d4b8a7;
    border-radius: 8px;
    transform: scale(1.05);
}