:root {
  --primary-color: #4a4e69;
  --secondary-color: #9a8c98;
  --accent-color: #c9ada7;
  --background-color: #f2e9e4;
  --text-color: #22223b;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

nav {
  background-color: var(--primary-color);
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  height: 100px;
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-left: 2rem;
}

nav ul li a {
  color: var(--background-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: var(--accent-color);
}

.login-btn {
  background-color: var(--accent-color);
  color: var(--primary-color) !important;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: var(--secondary-color);
}

.cta-button {
  display: inline-block;
  background-color: var(--accent-color);
  color: var(--primary-color);
  padding: 0.8rem 2rem;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 700;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--secondary-color);
}

section {
  padding: 5rem 0;
}

section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

#about {
  background-color: var(--secondary-color);
  color: var(--background-color);
}

#about p {
  max-width: 600px;
  margin: 0 auto 2rem auto;
}

footer {
  background-color: var(--primary-color);
  color: var(--background-color);
  text-align: center;
  padding: 2rem 0;
}

@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 0.5rem 0;
  }

  .app-card {
    width: 100%;
  }
}

/* .main-content {
  padding-top: 80px;
 
} */