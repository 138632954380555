.rules-lawyer-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: var(--HB_Color_Background, #EEE5CE);
}

:root {
    --HB_Color_Background: #EEE5CE;
    --HB_Color_Accent: #E0E5C1;
    --HB_Color_HeaderUnderline: #C0AD6A;
    --HB_Color_HorizontalRule: #9C2B1B;
    --HB_Color_HeaderText: #58180D;
    --HB_Color_MonsterStatBackground: #F2E5B5;
    --HB_Color_CaptionText: #766649;
    --HB_Color_WatercolorStain: #BBAD82;
    --HB_Color_Footnotes: #C9AD6A;
}

.controls {
    margin-bottom: 20px;
}

h1 {
    text-align: center;
    margin-bottom: 30px;
}