.chat-container {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem 20px;
    height: calc(100vh - 250px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: rgba(255, 253, 245, 0.95);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-messages {
    height: calc(100% - 100px);
    overflow-y: auto;
    padding: 20px;
    margin: 0 -20px;
}

.message {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
}

.message.user {
    background-color: #e3f2fd;
    margin-left: 20%;
}

.message.assistant {
    background-color: #f5f5f5;
    margin-right: 20%;
}

.message.error {
    background-color: #ffebee;
    margin-right: 20%;
}

.chat-input {
    display: flex;
    padding: 15px;
    border-top: 1px solid #ccc;
}

.chat-input input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.chat-input button {
    padding: 8px 20px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.chat-input button:hover {
    background-color: #1565c0;
}

.loading-indicator {
    font-style: italic;
    color: #888;
    margin-top: 10px;
}

.prompt-bubbles {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
}

.prompt-bubble {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 8px 15px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.2s;
}

.prompt-bubble:hover {
    background-color: #e0e0e0;
}